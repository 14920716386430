<template>
    <div
        class="mx-0"
        v-if="request"
    >
        <div class="col-12">
            <router-link to="/business/feedback/requests" class="back-to-requests">
                <img src="/media/buying/icons/arrow-left.svg" class="me-1" alt="">
                {{ $t("Back to Requests") }}
            </router-link>
            <BusinessRequestItem
                :requestData="request"
                @handle-toggle-status="toggleRequestStatus"
            />
        </div>
        <div v-if="userInfo.can(BusinessUserPermissionTypeEnum.COMMENT_ON_REQUESTS)" class="card my-10 pt-7">
            <div class="row">
                <div class="col-12">
                    <p class="fs-7 text-gray-500">{{ $t("Comment as") }} {{ userInfo.full_name }}</p>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-lg-8 col-xl-9 pe-lg-10">
                            <el-input
                                class="comment-box"
                                v-model="comment"
                                @keydown.enter="addComment(comment)"
                                :placeholder="$t('Leave your comment here...')"
                            />
                        </div>
                        <div class="col-12 col-lg-4 col-xl-3">
                            <button
                                @click="addComment(comment)"
                                class="btn mt-5 mt-lg-0 main-btn py-5 w-100 mw-100"
                                :disabled="!comment || commentBtnLoading"
                            >
                            <span v-if="commentBtnLoading"
                                  class="spinner-border spinner-border-sm align-middle me-4"></span>
                                {{ $t("Post Comment") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-10 comment-card">
            <p class="text-gray">
                {{ $t("Comments") }}
                <span class="text-with-bg" v-if="request.commentsContainer.comments.size">
      {{ request.commentsContainer.comments.size }}
    </span>
            </p>
            <div>
                <div v-if="!request.commentsContainer.comments.size" class="no-comments"> {{ $t("No comments yet") }}
                </div>
                <Comment
                    v-else
                    v-for="(item, index) in request.commentsContainer.getComments()"
                    :key="index"
                    :item="item"
                    :deletingItem="deletingItem"
                    @handle-update="updateComment"
                    @handle-delete="deleteComment"
                />
            </div>
        </div>
    </div>
    <div v-else class="mx-0">
        <CustomLoader />
    </div>
</template>
<script>
import Comment from "@/buying-teams/shared-components/request-idea/Comment";
import BusinessRequestItem from "@/buying-teams/pages/business/requests/BusinessRequestItem";
import store from "../../../../store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { RequestStatusModel } from "@/store/models/request/RequestStatusModel";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { PlatformTypeEnum } from "@/store/enums/PlatformTypeEnum";
import { BusinessUserPermissionTypeEnum } from "@/store/enums/business/BusinessUserPermissionTypeEnum";

export default {
    name: "SingleRequest",

    components: {
        CustomLoader,
        BusinessRequestItem,
        Comment
    },

    data() {
        return {
            PlatformTypeEnum,
            request: null,
            commentLoading: false,
            commentBtnLoading: false,
            deletingItem: null,
            comment: "",
            BusinessUserPermissionTypeEnum
        };
    },

    computed: {
        businessBanks() {
            return store.getters.businessBanks;
        },
        businessCountries() {
            return store.getters.businessCountries;
        },
        businessProductAreas() {
            return store.getters.businessProductAreas;
        },
        timePeriods() {
            return store.getters.timePeriods;
        },
        userInfo() {
            return store.getters.currentUser;
        }
    },

    async mounted() {
        setCurrentPageBreadcrumbs({ title: `${this.$t("hi")} ${this.userInfo.first_name},  <span>${this.$t("welcomeBack")}</span>` });

        this.getRequest();
    },

    methods: {
        async getRequest() {
            this.loading = true;
            store.dispatch("getRequestDetails", +this.$route.params.id).then((res) => {
                if (res) this.request = res;
            });
        },

        async addComment(comment) {
            if (!comment && this.commentBtnLoading) return;
            this.commentBtnLoading = true;
            let data = {
                intent_id: this.$route.params.id,
                body: comment
            };
            store.dispatch("saveComment", data).then(res => {
                this.comment = "";
                this.request.commentsContainer.addComment(res.data);
                this.request.detectCommentAction("add");
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.commentBtnLoading = false;
            });
        },

        async updateComment(payload) {
            this.request.commentsContainer.updateComment(payload);
        },

        async deleteComment(id) {
            this.deletingItem = id;
            store.dispatch("deleteComment", id).then(() => {
                this.request.commentsContainer.deleteComment(id);
                this.request.detectCommentAction("delete");
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.deletingItem = null;
            });
        },

        async toggleRequestStatus(requestId) {
            let status = this.request.status.code === RequestStatusEnum.OPEN ? RequestStatusEnum.CLOSED : RequestStatusEnum.OPEN;
            const params = {
                id: requestId,
                status: status
            };

            await store.dispatch("toggleRequestStatus", params)
                .then(res => {
                    if (res) {
                        this.request.status = new RequestStatusModel(status);
                    }
                });
        }
    }
};
</script>
<style lang="scss">
.back-to-requests {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #435BF4;
    margin-bottom: 14px;
    display: inline-block;
}

.comment-box {
    input {
        height: 50px;
        background: #F8F8F8;
        box-shadow: inset 0px 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
    }
}

.no-comments {
    margin-top: 10px;
    text-align: center;
    color: #959595;
}

.comment-card {
    overflow: hidden;

    > .text-gray {
        font-weight: 400;
        font-size: 14px;
        line-height: 13px;
        color: rgba(117, 117, 117, 0.5);

        .text-with-bg {
            background: #E0E0E0;
            border-radius: 3px;
        }
    }
}
</style>
